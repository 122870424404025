@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::after,
*::before {
  font-family: 'Poppins', sans-serif;
}

.heading-primary {
  font-weight: 900;
  font-size: 150px;
  line-height: 1.2;
  letter-spacing: 0px;
  @media screen and (max-width: 1300px) {
    font-size: 100px;
  }
  @media screen and (max-width: 1050px) {
    font-size: 80px;
  }
  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
}

.heading-primary-2 {
  font-weight: 900;
  font-size: 80px;
  letter-spacing: 8.3px;
  @media screen and (max-width: 1300px) {
    font-size: 60px;
    letter-spacing: 6px;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
}

.heading-primary-3 {
  font-weight: 900;
  font-size: 40px;
  letter-spacing: 0px;
  @media screen and (max-width: 1300px) {
    font-size: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 25px;
    letter-spacing: 2px;
  }
}

.heading-secondary {
  letter-spacing: 9.51px;
  font-size: 25px;
  @media screen and (max-width: 1300px) {
    font-size: 18px;
    letter-spacing: 6px;
  }
  @media screen and (max-width: 600px) {
    font-size: 15px;
  }
}

.heading-secondary-2 {
  letter-spacing: 2.22px;
  font-size: 22px;
  font-weight: 400;
  @media screen and (max-width: 1300px) {
    font-size: 18px;
    letter-spacing: 2.22px;
  }
  @media screen and (max-width: 600px) {
    font-size: 15px;
  }
}
