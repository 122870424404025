html {
  height: 100%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.pattern-pos-1 {
  position: absolute;
  right: -50%;
}

.pattern-pos-2 {
  position: absolute;
  left: -50%;
}

.pattern-pos-3 {
  position: absolute;
  right: -45%;
  top: 30%;
}

.rounded-lg-1 {
  border-radius: 1.1rem !important;
}

.padding-1 {
  padding-bottom: 350px;
  @media screen and (max-width: 600px) {
    padding-bottom: 150px;
  }
}

.padding-top-1 {
  padding-top: 40%;
  @media screen and (max-width: 600px) {
    padding-top: 24px;
  }
}

.height-1 {
  height: 400px;
  @media screen and (max-width: 1100px) {
    height: 100%;
  }
}

.margin-left-1 {
  margin-left: 10%;
  @media screen and (max-width: 600px) {
    margin: auto;
  }
}

.icon-hover {
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
}

.banner {
  height: 400px;
  background-position: bottom right;
  background-repeat: no-repeat;
  @media screen and (max-width: 600px) {
    height: 250px;
  }
}

.z-10 {
  position: relative;
  z-index: 10;
}
