@charset "UTF-8";
:root {
  --z24: translateZ(24.5px);
  --z80: translateZ(80px);
  --ts: all 0.5s ease;
  --x90: rotateX(90deg);
  --xm90: rotateX(-90deg);
  --y90: rotateY(90deg);
  --ym90: rotateY(-90deg);
}

nav {
  width: 100%;
  padding: 16px 16px 0 16px;
  position: relative;
}

ol {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-left: 0 !important;
}

.main-menu {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  justify-content: center;
}

li {
  width: 160px;
  position: relative;
  cursor: pointer;
}
li span {
  width: 100%;
  height: 49px;
  display: block;
  position: relative;
  transition: var(--ts);
  transform-style: preserve-3d;
}
li span::before,
li span::after {
  width: 100%;
  padding: 16px 0;
  display: block;
  position: absolute;
  border-radius: 8px;
  font: 600 12px 'Poppins';
  text-align: center;
  text-transform: uppercase;
}

.main-item:nth-of-type(2),
.main-item:nth-of-type(4) {
  margin: 0 2px;
}

.main-item::before,
.main-item:nth-of-type(2)::before {
  width: 100%;
  top: 25px;
  display: block;
  position: absolute;
  color: $color-white;
  font: 400 24px 'Consolas';
  text-align: center;
  transition: var(--ts);
  z-index: 100;
  content: '▾';
}

.main-item::after {
  color: $color-white;
}

.main-item.active > span,
.main-item:hover > span {
  transform: var(--x90);
}
.main-item > span::before {
  color: $color-white;
  transform: var(--z24);
  box-shadow: 0 0 3px 0.5px #888;
}
.main-item > span::after {
  color: $color-white;
  transform: var(--xm90) var(--z24);
}

.widgets::before,
.widgets::after {
  content: 'Case Studies';
}

.sub-menu {
  top: 51px;
  display: none;
  position: absolute;
}
.sub-menu a {
  opacity: 0;
  transform: translateY(-10px);
  display: block;
}
.sub-menu a > span {
  margin: 10px 0;
}

.sub-item {
  -webkit-animation: fade 0.5s ease 0.2s both;
  animation: fade 0.5s ease 0.2s both;
}

.sub-item:nth-of-type(2) {
  -webkit-animation: fade 0.5s ease 0.4s both;
  animation: fade 0.5s ease 0.4s both;
}

.sub-item:nth-of-type(3) {
  -webkit-animation: fade 0.5s ease 0.6s both;
  animation: fade 0.5s ease 0.6s both;
}

.sub-item:nth-of-type(4) {
  -webkit-animation: fade 0.5s ease 0.8s both;
  animation: fade 0.5s ease 0.8s both;
}

.sub-item:nth-of-type(5) {
  -webkit-animation: fade 0.5s ease 0.99s both;
  animation: fade 0.5s ease 0.99s both;
}

@-webkit-keyframes fade {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.sub-item span::before {
  background: $color-primary;
  color: $color-white;
  transform: var(--z80);
  box-shadow: 0 0 3px 0.5px #888;
}
.sub-item span::after {
  background: $color-blue;
  color: $color-white;
  box-shadow: 0 0 3px 0.5px #888;
}

.item-left.active span,
.item-left:hover span {
  transform: var(--y90);
}
.item-left span::after {
  transform: var(--ym90) var(--z80);
}

.item-right:hover span {
  transform: var(--ym90);
}
.item-right span::after {
  transform: var(--y90) var(--z80);
}

.link-name-1::before,
.link-name-1::after {
  content: 'Best Party';
}

.link-name-2::before,
.link-name-2::after {
  content: 'Builder';
}

.link-name-3::before,
.link-name-3::after {
  content: 'Smart Weight';
}

.link-name-4::before,
.link-name-4::after {
  content: 'Advyantt';
}

.link-name-5::before,
.link-name-5::after {
  content: 'Blox Win';
}

.main-item:hover::before,
.main-item:nth-of-type(2):hover::before {
  color: $color-white;
  content: '▴';
}
.main-item:hover .sub-menu {
  display: block;
}

.main-menu > li {
  @media screen and (max-width: 400px) {
    width: 126px;
  }
}

// Color

.main-item-0 > span::before {
  background: #a91bae;
}
.main-item-0 > span::after {
  background: $color-blue;
}

.main-item-1 > span::before {
  background: $color-white;
  color: $color-black !important;
}
.main-item-1 > span::after {
  background: #e663be;
}

.main-item-2 > span::before {
  background: $color-white;
  color: $color-black !important;
}
.main-item-2 > span::after {
  background: #f5a020;
}

.main-item-3 > span::before {
  background: $color-white;
  color: $color-black !important;
}
.main-item-3 > span::after {
  background: #7b32ce;
}

.main-item-4 > span::before {
  background: $color-white;
  color: $color-black !important;
}
.main-item-4 > span::after {
  background: #6cd1ab;
}
