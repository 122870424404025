$theme-colors: (
  'primary': #a91bae,
  'secondary': #050505b3,
  'info': #3fb5be,
  'warning': #f5a020,
  'light': #6800df,
  'danger': #be3f98,
  'success': #3fbe8e,
);

$color-primary: #a91bae;
$color-blue: #40349c;
$color-white: #fff;
$color-black: #000;

.bg-navbar {
  background-color: $color-black;
}

.bg-black {
  background-color: #252525;
}
